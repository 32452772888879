import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import './footer.scss';

import Logo from '../../assets/logo.svg';

const partnerBox = () => (
  <div className="footer">
    <div className="wrapper">
      <Row>
        <Col xs={12} sm={6} md={4}>
          <div className="footer-box">
            <img src={Logo} alt="logo" width="60px" />
            <p>
              Ungefair Miljökonsult AB
              <br />
              Org nr 55 93 48 – 1368, godkänd för F-skatt
            </p>
          </div>
        </Col>
        <Col xs={12} sm={6} md={4}>
          <div className="footer-box">
            <p>
              info@ungefair.se
              <br />
              Bankgiro: 5783-6884
            </p>
          </div>
        </Col>
        <Col xs={12} sm={6} md={4}>
          <div className="footer-box">
            <p>
              Götgatan 73B, 4 Tr
              <br />
              116 62 Stockholm
            </p>
          </div>
        </Col>
      </Row>
    </div>
  </div>
);
export default partnerBox;
