import React from 'react';
import { Row, Col } from 'react-flexbox-grid';

import './title.scss';

const title = (props) => (
  <Row>
    <Col className="big__title">
      <h3 className="weight800 font40 padding20">{props.title}</h3>
    </Col>
  </Row>
);

export default title;
