import React from 'react';
// SCSS
import './teamInfo.scss';

const about = ({ title, text }) => (
  <div className="team__info flex-center">
    <div>
      <strong>{title}</strong>
      <p className="font12 weight500">{text}</p>
    </div>
  </div>
);

export default about;
