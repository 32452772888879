import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
// SCSS
import './hero.scss';
//Assets
import HeroImage from '../../assets/hero/hero-image.jpg';
//Components

const hero = () => (
  <div className="hero" id="hero">
    <div className="wrapper">
      <Row>
        <Col md={12} lg={6}>
          <div className="hero-info">
            <h1 className="weight800 font60">Ungefair Miljökonsult AB</h1>
            <p className="font16 weight600">
              <strong>
                Vi levererar på ert behov av miljö- och hållbarhetsstöd.
              </strong>
            </p>
            <p>
              Men som namnet på vårt bolag antyder, så har vi fler strängar på
              vår lyra. Så säg till om ni i stället behöver hjälp med
              kommunikation och management, golfklubbsledning eller kanske helt
              enkelt bara vill bli bättre på bridge! Vi är två seniora konsulter
              med lång erfarenhet från många branscher och situationer. Vi tar
              enskilda personliga uppdrag, men stöttar och samarbetar vid behov
              i det dagliga arbetet.
            </p>
          </div>
        </Col>
        <Col md={12} lg={6}>
          <div className="hero-image">
            <img src={HeroImage} alt="hero" />
          </div>
        </Col>
      </Row>
    </div>
  </div>
);

export default hero;
