import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import './about.scss';
// Components
import TeamBox from './teamBox';
import TeamInfo from './teamInfo';
import Title from '../ui-components/title/title';
// Assets
import Person01 from '../../assets/about/matilde_unge.jpg';
import Person02 from '../../assets/about/ragnar_unge.jpg';

const about = () => (
  <div id="about">
    <div className="wrapper">
      <Title title="Och vi, det är:" />
      <Row>
        <Col md={12} lg={3}>
          <TeamBox avatar={Person01} jpg name="Matilde Unge" />
        </Col>
        <Col md={12} lg={3}>
          <TeamInfo
            title="Civilingenjör i samhällsbyggnadsteknik."
            text="Har arbetat i miljö- och hållbarhetspositioner på SIS
Miljömärkning, Kemi & Miljö AB, Johnson Wax Professional, 3M, Miljöstyrningsrådet, Skanska,
Liljewall arkitekter och Veidekke. Och med allt från miljöutbildning till att vara hållbarhetsstrateg
och miljöchef."
          />
        </Col>
        <Col md={12} lg={3}>
          <TeamBox avatar={Person02} jpg name="Ragnar Unge" />
        </Col>
        <Col md={12} lg={3}>
          <TeamInfo
            title="Marknadsekonom och lärare."
            text="Har som marknadschef och sedermera vd under 25 år byggt upp
miljömärkningen Svanen till vad den är idag. Nu olika styrelseuppdrag, vd-coach,
golfklubbsledning och bridgelärare."
          />
        </Col>
      </Row>
      <Row></Row>
    </div>
  </div>
);

export default about;
