import React from 'react';
import { Link } from 'react-scroll';
// SCSS
import './navbar.scss';
// Assets
import LogoImg from '../../assets/logo_full_gray.svg';

const desktopNav = (props) => (
  <nav className={`Navbar ${!props.userIsScrolled ? 'extraLargeNavbar' : ''}`}>
    <div className="wrapper flex-s-between">
      <div>
        <Link to="hero" spy={true} smooth={true} offset={0} duration={500}>
          <img src={LogoImg} alt="logo" className="pointer" width="300px" />
        </Link>
      </div>
    </div>
  </nav>
);

export default desktopNav;
